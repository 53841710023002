import { createError, defineNuxtRouteMiddleware } from 'nuxt/app';
import { storeToRefs } from 'pinia';
import type { RouteLocationNormalized } from 'vue-router';

import { PAGE_NOT_FOUND_404 } from '@/constants/error.const';
import useProductStore from '@/stores/product.store';
import type { ProductResponse } from '@/types/product/product-response.type';
import { isDirectAccess, redirectTo } from '@/utils/common.util';

export default defineNuxtRouteMiddleware(
  async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    const { params: toParams, name: toName } = to;
    const { name: fromName, params: fromParams } = from;
    const productStore = useProductStore();
    const { product } = storeToRefs(productStore);

    const toProductId = toParams.productId as string;
    const toProjectId = toParams.projectId as string;

    const fromProductId = fromParams.productId as string;

    let hasError = false;

    if (!product.value || toProductId !== fromProductId) {
      try {
        await productStore.fetchProductsInfo(toProductId);
        await productStore.getProductPlatform();
        if (product.value) {
          const projectId = (product.value as ProductResponse)?.projectId;
          if (product.value && projectId !== toProjectId) {
            hasError = true;
          }
        }
      } catch (error) {
        hasError = true;
      }
    }

    if (hasError) {
      product.value = undefined;
      if (isDirectAccess(toName, fromName)) {
        throw createError({
          statusCode: PAGE_NOT_FOUND_404,
          message: 'Page not found'
        });
      }
      return await redirectTo(to.fullPath, { external: true });
    }
  }
);
